import { useCallback, useEffect, useState } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { getBumpOptions } from 'data/api'
import { transformBumpOptionsResponse } from 'data/api/transformers/response'
import { BumpOptionModel } from 'types/models/bump'

import { PreCheckoutModal } from '../../../../constants/bumps'
import useBumpCheckoutContext from '../../../../hooks/useBumpCheckoutContext'

const useBumpOptions = (activeModal: PreCheckoutModal) => {
  const { selectedItems } = useBumpCheckoutContext()

  const [selectedBumpOption, setSelectedBumpOption] = useState<BumpOptionModel | null>(null)
  const [isOptionsDataStale, setIsOptionsDataStale] = useState(false)

  const {
    fetch: fetchBumpOptions,
    transformedData: bumpOptions,
    isLoading: isBumpOptionsLoading,
    error: bumpOptionsError,
  } = useFetch(getBumpOptions, transformBumpOptionsResponse)

  const updateSelectedBumpOption = useCallback(
    (optionsData: BumpOptionModel) => {
      setSelectedBumpOption(optionsData)
    },
    [setSelectedBumpOption],
  )

  useEffect(() => {
    setIsOptionsDataStale(true)
  }, [selectedItems])

  useEffect(() => {
    if (activeModal === PreCheckoutModal.None) return
    if (!isOptionsDataStale) return
    if (!selectedItems.length) return
    fetchBumpOptions({ itemIds: selectedItems.map(item => item.id) })
    setIsOptionsDataStale(false)
  }, [fetchBumpOptions, selectedItems, isOptionsDataStale, activeModal])

  useEffect(() => {
    if (!bumpOptions?.options.length) return
    const defaultOption =
      bumpOptions.options.find(option => option.isDefault) || bumpOptions.options[0]!

    updateSelectedBumpOption(defaultOption)
  }, [bumpOptions, updateSelectedBumpOption])

  return {
    bumpOptions,
    isBumpOptionsLoading,
    bumpOptionsError,
    selectedBumpOption,
    updateSelectedBumpOption,
  }
}

export default useBumpOptions
